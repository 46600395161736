/* overrides one trust link styles applied to footer DNSoS 'Do Not Sell or Share My Personal Information' item */

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
  color: white !important;
  padding: 0 !important;
  border: 0 !important;
  white-space: unset !important;
  transition: none !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover {
  color: #fff !important;
  background-color: transparent !important;
}