// To use this file, import themes.scss and reference vars.
// Ex. background-color: var($--theme-background);
@import './variables.scss';

$--theme-color: --theme-color;
$--theme-drop-cap-color: --theme-drop-cap-color;
$--theme-hero-drop-cap-color: --theme-hero-drop-cap-color;
$--theme-drop-cap-bg-image: --theme-drop-cap-bg-image;
$--theme-text-color: --theme-text-color;
$--theme-carousel-color: --theme-carousel-color;
$--theme-background: --theme-background;
$--theme-secondary-background: --theme-secondary-background;
$--theme-spotlight-background: --theme-spotlight-background;
$--theme-house-adjective-color: --theme-house-adjective-color;
$--theme-background-rgb: --theme-background-rgb;
$--theme-background-repeat: --theme-background-repeat;
$--theme-background-attachment: --theme-background-attachment;
$--theme-background-size: --theme-background-size;
$--theme-background-image-url-mobile: --theme-background-image-url-mobile;
$--theme-background-image-url-md: --theme-background-image-url-md;
$--theme-background-image-url-lg: --theme-background-image-url-lg;
$--theme-background-image-url-xl: --theme-background-image-url-xl;
$--theme-text-link-color: --theme-text-link-color;
$--theme-divider: --theme-divider;

// Mapping between theme vars and colors, later used by the mixin to
// generate the proper CSS in base.scss.
$theme-map-dark: (
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $grey-legal-darkest,
  $--theme-background-rgb: $grey-legal-darkest-rgb,
  $--theme-secondary-background: $grey-feature,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $lightMagic,
  $--theme-spotlight-background: $ww-black,
);

$theme-map-discover: (
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $ww-black,
  $--theme-background-rgb: $ww-black-rgb,
  $--theme-secondary-background: $grey-feature,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $lightMagic,
  $--theme-spotlight-background: $ww-black,
);

$theme-map-light: (
  $--theme-text-color: $grey-legal-darkest,
  $--theme-carousel-color: $grey-legal-darkest,
  $--theme-background: $gold-light,
  $--theme-background-rgb: $gold-light-rgb,
  $--theme-secondary-background: $gold-light,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $lightMagic,
  $--theme-spotlight-background: $purple-light,
);

$theme-map-medium: (
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $medium-purple,
  $--theme-background-rgb: $medium-purple-rgb,
  $--theme-secondary-background: $medium-purple,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $lightMagic, 
  $--theme-spotlight-background: $spotlight-theme-light,
);

// TODO: Determine how to handle bg color with header image bg
$theme-map-fan-club: (
  $--theme-text-color: $port-gore,
  $--theme-carousel-color: $white,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $lightMagic,
  $--theme-background-rgb: $white-rgb,
  $--theme-secondary-background: $white,
  $--theme-spotlight-background: $port-gore,
);

$theme-map-campaign: (
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $white,
  $--theme-background: $campaign-purple,
  $--theme-background-rgb: $campaign-purple-rgb,
  $--theme-secondary-background: $campaign-purple,
  $--theme-background-repeat: repeat-y,
  $--theme-background-size: contain,
  $--theme-background-image-url-mobile: url('/images/pages/campaign/backgrounds/default-mobile.png'),
  $--theme-background-image-url-md: url('/images/pages/campaign/backgrounds/default-768.png'),
  $--theme-background-image-url-lg: url('/images/pages/campaign/backgrounds/default-1025.png'),
  $--theme-background-image-url-xl: url('/images/pages/campaign/backgrounds/default.png'),
  $--theme-spotlight-background: $campaign-purple,
);

$theme-map-ravenclaw: (
  $--theme-drop-cap-color: $ravenclawGrad2,
  $--theme-hero-drop-cap-color: $ravenclawGrad2,
  $--theme-drop-cap-bg-image: none,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $black,
  $--theme-background-rgb: $black-rgb,
  $--theme-secondary-background: $ravenclaw,
  $--theme-spotlight-background: $ravenclawBGCircle,
  $--theme-house-adjective-color: $ravenclawAdjective,
);

$theme-map-slytherin: (
  $--theme-drop-cap-color: $slytherinGrad2,
  $--theme-hero-drop-cap-color: $slytherinGrad2,
  $--theme-drop-cap-bg-image: none,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $black,
  $--theme-background-rgb: $black-rgb,
  $--theme-secondary-background: $slytherin,
  $--theme-spotlight-background: $user-slytherin,
  $--theme-house-adjective-color: $slytherinAdjective,
);

$theme-map-gryffindor: (
  $--theme-drop-cap-color: $gryffindorGrad1,
  $--theme-hero-drop-cap-color: $gryffindorGrad1,
  $--theme-drop-cap-bg-image: none,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $black,
  $--theme-background-rgb: $black-rgb,
  $--theme-secondary-background: $gryffindor,
  $--theme-spotlight-background: $gryffindorBGCircle,
  $--theme-house-adjective-color: $gryffindorAdjective,
);

$theme-map-hufflepuff: (
  $--theme-drop-cap-color: $hufflepuffBGCircle,
  $--theme-hero-drop-cap-color: $hufflepuffBGCircle,
  $--theme-drop-cap-bg-image: none,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $black,
  $--theme-background-rgb: $black-rgb,
  $--theme-secondary-background: $hufflepuff,
  $--theme-spotlight-background: $hufflepuffBGCircle,
  $--theme-house-adjective-color: $hufflepuffAdjective,
);

$theme-map-noHouse: (
  $--theme-drop-cap-color: $noHouse,
  $--theme-hero-drop-cap-color: $noHouse,
  $--theme-drop-cap-bg-image: none,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $black,
  $--theme-background-rgb: $black-rgb,
  $--theme-secondary-background: $noHouse,
  $--theme-spotlight-background: $noHouse,
);

$theme-map-user-gryffindor: (
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $user-gryffindor,
  $--theme-drop-cap-bg-image: $gryffindorDropCap,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $user-gryffindor,
  $--theme-background-rgb: $user-gryffindor-rgb,
  $--theme-secondary-background: $user-gryffindor,
  $--theme-spotlight-background: $user-gryffindor,
);

$theme-map-user-hufflepuff: (
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $user-hufflepuff,
  $--theme-drop-cap-bg-image: $hufflepuffDropCap,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $user-hufflepuff,
  $--theme-background-rgb: $user-hufflepuff-rgb,
  $--theme-secondary-background: $user-hufflepuff,
  $--theme-spotlight-background: $user-hufflepuff,
);

$theme-map-user-ravenclaw: (
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $user-ravenclaw,
  $--theme-drop-cap-bg-image: $ravenclawDropCap,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $user-ravenclaw,
  $--theme-background-rgb: $user-ravenclaw-rgb,
  $--theme-secondary-background: $user-ravenclaw,
  $--theme-spotlight-background: $user-ravenclaw,
);

$theme-map-user-slytherin: (
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $user-slytherin,
  $--theme-drop-cap-bg-image: $slytherinDropCap,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $user-slytherin,
  $--theme-background-rgb: $user-slytherin-rgb,
  $--theme-secondary-background: $user-slytherin,
  $--theme-spotlight-background: $user-slytherin,
);

$theme-map-user-no-house: (
  $--theme-drop-cap-color: $white,
  $--theme-hero-drop-cap-color: $user-no-house,
  $--theme-drop-cap-bg-image: $noHouseDropCap,
  $--theme-text-color: $white,
  $--theme-carousel-color: $white,
  $--theme-background: $user-no-house,
  $--theme-background-rgb: $user-no-house-rgb,
  $--theme-secondary-background: $user-no-house,
  $--theme-spotlight-background: $user-no-house,
);

$theme-map-fact-file-characters-and-pets: (
  $--theme-secondary-background: $charactersSecondaryBG,
  $--theme-background: $charactersBG,
  $--theme-spotlight-background: $charactersSpotlightBackground,
  $--theme-text-link-color: $charactersLinkColor,
  $--theme-divider: $charactersDivider,
  $--theme-color: $charactersLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-objects: (
  $--theme-secondary-background: $objectsSecondaryBG,
  $--theme-background: $objectsBG,
  $--theme-spotlight-background: $objectsSpotlightBackground,
  $--theme-text-link-color: $objectsLinkColor,
  $--theme-divider: $objectsDivider,
  $--theme-color: $objectsLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-locations: (
  $--theme-secondary-background: $locationsSecondaryBG,
  $--theme-background: $locationsBG,
  $--theme-spotlight-background: $locationsSpotlightBackground,
  $--theme-text-link-color: $locationsLinkColor,
  $--theme-divider: $locationsDivider,
  $--theme-color: $locationsLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-plants-and-potions: (
  $--theme-secondary-background: $potionsSecondaryBG,
  $--theme-background: $potionsBG,
  $--theme-spotlight-background: $potionsSpotlightBackground,
  $--theme-text-link-color: $potionsLinkColor,
  $--theme-divider: $potionsDivider,
  $--theme-color: $potionsLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-creatures: (
  $--theme-secondary-background: $creaturesSecondaryBG,
  $--theme-background: $creaturesBG,
  $--theme-spotlight-background: $creaturesSpotlightBackground,
  $--theme-text-link-color: $creaturesLinkColor,
  $--theme-divider: $creaturesDivider,
  $--theme-color: $creaturesLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-spells: (
  $--theme-secondary-background: $spellsSecondaryBG,
  $--theme-background: $spellsBG,
  $--theme-spotlight-background: $spellsSpotlightBackground,
  $--theme-text-link-color: $spellsLinkColor,
  $--theme-divider: $spellsDivider,
  $--theme-color: $spellsLabel,
  $--theme-text-color: $white,
);

$theme-map-fact-file-magical-miscellany: (
  $--theme-secondary-background: $miscSecondaryBG,
  $--theme-background: $miscBG,
  $--theme-spotlight-background: $miscSpotlightBackground,
  $--theme-text-link-color: $miscLinkColor,
  $--theme-divider: $miscDivider,
  $--theme-color: $miscLabel,
  $--theme-text-color: $white,
);
