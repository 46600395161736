@import '../../styles/variables';
@import '../../styles/mixins';

.root {
  box-sizing: border-box;
  font-family: $sofiapro;
  font-weight: 400;
  color: $gold-light;
  letter-spacing: 1px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 20px;
  background-color: $lightMagic;
  z-index: 11;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(xm) {
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    .completionText {
      padding: 5px 0 20px;
    }
  }
}
