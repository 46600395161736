@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_200.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_250.woff2') format('woff2');
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_400.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  // 600 weight is not available
  src: url('/fonts/sofia-pro/normal_normal_500.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_700.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_800.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/normal_normal_900.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_200.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_250.woff2') format('woff2');
  font-weight: 250;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_300.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_400.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_500.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  // 600 weight is not available
  src: url('/fonts/sofia-pro/italic_normal_500.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_700.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_800.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/fonts/sofia-pro/italic_normal_900.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
