@font-face {
  font-family: 'Harry Beast Regular';
  src: url('/fonts/harry-beast/HarryBeastStandard-Regular.woff2') format('woff2'),
    url('/fonts/harry-beast/HarryBeastStandard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Harry Beast Display';
  src: url('/fonts/harry-beast/HarryBeastDisplay-Regular.woff2') format('woff2'),
    url('/fonts/harry-beast/HarryBeastDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

