@import '../../styles/variables.scss';

$primary-dark-magic: #0B163B;

$default-padding: 12px 45px 14px;
$default-font-size: 14px;
$default-line-height: 14px;

$small-padding: 6px 12px 8px;
$small-font-size: 12px;
$small-line-height: 12px;

$large-padding: 20px 40px 22px;
$large-font-size: 15px;
$large-line-height: 15px;

$default-letter-spacing: 1.63px;
$spinner-size: 14px;

$button-transition: all 0.1s ease-in;

// primary, hover, text variant for each house
$houses: (
  gryffindor: ($gryffindorPrimary, $gryffindorHover, $snow),
  hufflepuff: ($hufflepuffPrimary, $hufflepuffHover, $darkNavy),
  ravenclaw: ($ravenclawPrimary, $ravenclawHover, $snow),
  slytherin: ($slytherinPrimary, $slytherinHover, $snow),
  danger: ($dangerPrimary, $dangerHover, $snow),
);

@mixin hover-state {
  &:hover:not(.disabled):not(.loading) {
    @content;
  }
}

.button {
  position: relative;
  box-sizing: border-box;
  font-family: $sofiapro;
  font-size: $default-font-size;
  line-height: $default-line-height;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: $default-letter-spacing;
  color: $snow;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: $button-transition;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .contentLeft, .contentRight {
      margin-bottom: -4px;
    }

    svg, svg path {
      transition: $button-transition; 
    }
  }

  &.primary,
  &.secondary {
    padding: $default-padding;
    border-radius: 30px;
    border: 1px solid transparent;
  }

  &.sm {
    padding: $small-padding;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  &.lg {
    padding: $large-padding;
    font-size: $large-font-size;
    line-height: $large-line-height;
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.loading {
    cursor: wait;
    opacity: 0.5;
    
    .content {
      visibility: hidden;
    }
  }

  &.primary {
    background-color: $lightMagic;

    @include hover-state {
      background-color: $primary-dark-magic;
      border-color: $lightMagic;
    }

    @each $house, $colors in $houses {
      &.#{$house} {
        color: nth($colors, 3);
        background-color: nth($colors, 1);

        @include hover-state {
          background-color: nth($colors, 2);
          border-color: nth($colors, 1);
        }
      }
    }
  }

  &.secondary {
    background-color: transparent;
    border-color: $snow;

    @include hover-state {
      color: $black;
      border-color: transparent;
      background-color: $snow;

      svg, svg path {
        fill: $black;
      }
    }

    &.light {
      color: $primary-dark-magic;
      background-color: transparent;
      border-color: $primary-dark-magic;

      @include hover-state {
        color: $snow;
        border-color: transparent;
        background-color: $primary-dark-magic;

        svg, svg path {
          fill: $black;
        }
      }
    }

    @each $house, $colors in $houses {
      &.#{$house} {
        color: $snow;
        background-color: transparent;
        border-color: nth($colors, 1);

        @include hover-state {
          color: nth($colors, 3);
          border-color: transparent;
          background-color: nth($colors, 1);

          svg, svg path {
            fill: nth($colors, 3);
          }
        }

        &.light {
          color: $primary-dark-magic;

          @include hover-state {
            color: $snow;
            background-color: nth($colors, 1);

            svg, svg path {
              fill: nth($colors, 3);
            }
          }
        }
      }
    }
  }

  &.text {
    background-color: transparent;
    text-decoration: underline;
    padding: 0;

    @include hover-state {
      color: $lightMagic;
    }

    &.light {
      color: $primary-dark-magic;

      @include hover-state {
        color: $lightMagic;
      }
    }
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  width: $spinner-size;
  height: $spinner-size;
  margin: 0 auto;
  background: transparent;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}

@keyframes spin {
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}
