@import '../../styles/variables.scss';
@import '../../styles/mixins';

.scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inner {
  margin: auto;

  svg {
    // overwrite lottie inline style, fixes Safari blur issue
    transform: unset !important;
  }
}

.clickable {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

.title {
  @include sofiaRegular();
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.1px;
  color: #dfdbd9;
  text-transform: uppercase;
}

// Animation specific rules

.scroll-indicator {
  margin: 20px auto;

  .inner {
    width: 30px;
    height: 60px;
  }
}

.timeturner {
  .inner {
    width: 150px;
    height: 150px;
  }
}
