.key {
  animation: upDown 1.5s ease-in infinite;
}

.rightWing {
  animation: flapRight 1.5s ease-in infinite;
}
.leftWing {
  animation: flapLeft 1.5s ease-in infinite;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes flapRight {
  0% {
    transform-origin: center;
    transform: rotate(0);
  }
  50% {
    transform-origin: center;
    transform: rotate(-2deg);
  }
  100% {
    transform-origin: center;
    transform: rotate(0);
  }
}
@keyframes flapLeft {
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }
  50% {
    transform-origin: center;
    transform: rotate(2deg);
  }
  100% {
    transform-origin: center;
    transform: rotate(0deg);
  }
}
