@import './global.scss';

body,
.body {
  background-color: $ww-black;
  font-family: $sofiapro;
  color: $white;
  margin: 0px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: $grey-legal-border 1px solid;
}
