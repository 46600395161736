@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.loader {
  img,
  svg {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .loaderContainer {
    width: 75%;
    margin: 0 auto;
  }

  background-repeat: no-repeat;
  background-size: cover;
  @include backgroundImages('../components/Loaders/images/no-house');

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.messageContainer {
  margin-top: 30px;
  text-align: center;
  font-family: $sofiapro;
  font-size: $font-size-small;
  span {
    line-height: 2rem;
  }
}

.paintbrushLoader {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -125px;
  margin-top: -120px;
}
